import angular from "angular";

/* @ngInject */
export default class AssignStudyController {
  constructor(study, mode, $scope, $mdDialog, $window, $injector, Authentication, Config, $document) {
    this._study = study;
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._$window = $window;
    this._$rootScope = $injector.get("$rootScope");
    this._Authentication = Authentication;
    this._$document = $document;
    this._UserService = $injector.get("UserService");
    this._StudyAssignmentService = $injector.get("StudyAssignmentService");
    this._Facility = $injector.get("Facility");
    this._WorkflowsService = $injector.get("WorkflowsService");
    this.mode = mode;
    this.features = Config.features;
    this.confirmPopup = false;
    this.confirmFailed = false;
    this.userSearchTerm = "";

    this.eSignEnabled = this._WorkflowsService.workflowSettings[this._study.facilityId]?.eSignEnabled;

    this.$onInit = this._loadData;

    const deregisterWindowResize = this._$rootScope.$on("window-resize", () => {
      this.getDropdownOffset();
    });
    this._$scope.$on("$destroy", deregisterWindowResize);
  }

  /// Public Functions ///

  get submitIsDisabled() {
    return (
      this.assignStudyForm.$invalid ||
      this.assignStudyForm.$submitted ||
      !this.hasPhysicians() ||
      (this.eSignEnabled && !this.selectedUserIds)
    );
  }

  cancel() {
    this._$mdDialog.cancel("Closed assign study dialog");
  }

  clickedContinue() {
    this._$mdDialog.hide();
  }

  async clickedSubmit() {
    this.isLoading = true;

    let usersToUnassign = this._study.assignedUsers;

    // The users to unassign are those who are currently assigned but not selected
    if (this.selectedUserIds) {
      usersToUnassign = this._study.assignedUsers.filter(
        (assignedUser) => !this.selectedUserIds.includes(assignedUser.userId)
      );
    }

    if (this.mode === "studies") {
      await this._deselectStudy();
    }

    // Try to assign and unassign users to this study
    try {
      await this._StudyAssignmentService.assignStudyToUsers(
        this._study.id,
        this.selectedUserIds,
        this._study.facilityId
      );

      await this._StudyAssignmentService.clearAssignments(
        this._study.id,
        usersToUnassign,
        this._study.facilityId
      );

      await this._$mdDialog.hide();
    } catch (error) {
      console.error(error);
      const popupTitle = "Failed to assign study";
      const defaultMessage = `Unable to assign study. Status Code: ${error.status}`;
      await this._displayErrorMessage(popupTitle, defaultMessage, error);
    }

    this.isLoading = false;
  }

  displayConfirm() {
    this.confirmPopup = true;
  }

  cancelConfirm() {
    this.confirmPopup = false;
    this.searchTextConfirm = "";
    this.confirmDevice = null;
  }

  getDropdownOffset() {
    if (!this.hasPhysicians()) {
      return;
    }
    const myElement = this._$document[0].getElementsByClassName("selectContainer");
    const containerTop = `${
      myElement[0].getBoundingClientRect().top + myElement[0].getBoundingClientRect().height
    }px`;
    const myContainer = this._$document[0].getElementsByClassName("multiSelectHeader");
    const dropdownElement = this._$document[0].getElementById("assignStudyToUserSelect");
    const containerMinWidth = `${dropdownElement.getBoundingClientRect().width.toFixed(3)}px`;
    setTimeout(function setTop() {
      angular.element(myContainer).css({
        top: containerTop,
        minWidth: containerMinWidth,
      });
    }, 50);
  }

  clearSearchTerm() {
    this.userSearchTerm = "";
  }

  hasPhysicians() {
    return this.users && this.users.length !== 0;
  }

  /// Private Functions ///

  async _loadData() {
    this.isLoading = true;

    try {
      // If this study belongs to a delegate facility, physicians from the current user's
      // facility should be included in the assignment list
      const {
        user: {facility},
      } = this._Authentication.getJwtPayload();
      const studyBelongsToDelegate = Object.keys(facility.relationsToOwnerFacilities || {}).some((id) =>
        this._study.facilityId.includes(id)
      );

      const facilityIds = this._Facility.calculateAncestorFacilityIds(this._study.facilityId);
      if (studyBelongsToDelegate) {
        facilityIds.push(facility.id);
      }
      const userQuery = {
        state: {$notIn: ["pending", "disabled"]},
        role: "physician",
        facilityId: {$or: facilityIds},
      };

      this.users = await this._UserService.getUsers(userQuery);

      // this._study.assignedUsers must contain the assigned users
      const assignedUserIds = this._study.assignedUsers.map((user) => user.userId);
      if (assignedUserIds.length > 0) {
        // Prefill users assigned to this study
        this.assignStudyForm.assignStudyToUser.$$ngModelSet(this._$scope, assignedUserIds);
      }
    } catch (error) {
      const defaultError = "Failed to check for study assignments";
      this._$mdDialog.cancel(error.message || error.data?.detail?.message || defaultError);
    }

    this.isLoading = false;
    this._$scope.$apply();
  }

  _deselectStudy() {
    return new Promise((resolve) => {
      const deregister = this._$rootScope.$on("study-deselected", () => {
        resolve();
        deregister();
      });

      this._$rootScope.$emit("deselect-study");
    });
  }

  _displayErrorMessage(popupTitle, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
    );
  }
}
