import keyBy from "lodash/keyBy";

/* @ngInject */
export default class EditReportController {
  constructor(item, selectedItem, generatedReport, $rootScope, $scope, $stateParams, $injector) {
    this._$rootScope = $rootScope;
    this._$scope = $scope;
    this._$stateParams = $stateParams;
    this._$window = $injector.get("$window");
    this._$mdDialog = $injector.get("$mdDialog");
    this._$state = $injector.get("$state");
    this._Config = $injector.get("Config");
    this._backendConfig = $injector.get("backendConfig");

    this.item = item;
    this.selectedItem = selectedItem;
    this.generatedReport = generatedReport;

    this.$onInit = this._init;
  }

  async _init() {
    this.generatedReportId = this.generatedReport.id;
    this.technicianFindings = this.generatedReport.technicianFindings;
    this.meetsMdnCriteria = this.generatedReport.meetsMdnCriteria;
    this.chartToggles = this.generatedReport.chartToggles;
    this.includedStrips = keyBy(this.generatedReport.strips, "id");

    this.reportTitle = `Edit Report ${this.generatedReport.studyId}-${this.generatedReport.reportNumber}`;
    this.selectedUserRhythmClassification = "";
    this.createdBeatMarkers = [];
    this.deletedBeatMarkers = [];
  }

  close() {
    this._$mdDialog.cancel("Cancelled edits");
  }
}
