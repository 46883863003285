import angular from "angular";

/* @ngInject */
export default class AssignItemController {
  constructor(item, $scope, $mdDialog, $window, $injector, Authentication, Config, $document) {
    this._item = item;
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._$window = $window;
    this._$rootScope = $injector.get("$rootScope");
    this._Authentication = Authentication;
    this._$document = $document;
    this._UserService = $injector.get("UserService");
    this._ItemAssignmentService = $injector.get("ItemAssignmentService");
    this._InboxItemService = $injector.get("InboxItemService");
    this._Facility = $injector.get("Facility");
    this.features = Config.features;
    this.confirmPopup = false;
    this.confirmFailed = false;
    this.userSearchTerm = "";

    this.$onInit = this._loadData;

    const deregisterWindowResize = this._$rootScope.$on("window-resize", () => {
      this.getDropdownOffset();
    });
    this._$scope.$on("$destroy", deregisterWindowResize);
  }

  /// Public Functions ///

  get submitIsDisabled() {
    return this.assignItemForm.$invalid || this.assignItemForm.$submitted;
  }

  cancel() {
    this._$mdDialog.cancel("Closed assign item dialog");
  }

  async clickedSubmit() {
    this.isLoading = true;

    let usersToUnassign = this._item.assignedUsers;

    // The users to unassign are those who are currently assigned but not selected
    if (this.selectedUserIds) {
      usersToUnassign = this._item.assignedUsers.filter(
        (assignedUser) => !this.selectedUserIds.includes(assignedUser.userId)
      );
    }

    // Try to assign and unassign users to this study
    try {
      await this._ItemAssignmentService.assignItemToUsers(
        this._item.id,
        this.selectedUserIds,
        this._item.facilityId
      );

      await this._ItemAssignmentService.clearAssignments(
        this._item.id,
        usersToUnassign,
        this._item.facilityId
      );

      this._deselectInboxItem();
      this._$mdDialog.hide();
    } catch (error) {
      console.error(error);
      const popupTitle = "Failed to assign item";
      const defaultMessage = `Unable to assign item. Status Code: ${error.status}`;
      await this._displayErrorMessage(popupTitle, defaultMessage, error);
    }

    this.isLoading = false;
  }

  displayConfirm() {
    this.confirmPopup = true;
  }

  cancelConfirm() {
    this.confirmPopup = false;
    this.searchTextConfirm = "";
    this.confirmDevice = null;
  }

  getDropdownOffset() {
    const myElement = this._$document[0].getElementsByClassName("selectContainer");
    const containerTop = `${
      myElement[0].getBoundingClientRect().top + myElement[0].getBoundingClientRect().height
    }px`;
    const myContainer = this._$document[0].getElementsByClassName("multiSelectHeader");
    const dropdownElement = this._$document[0].getElementById("assignItemToUserSelect");
    const containerMinWidth = `${dropdownElement.getBoundingClientRect().width.toFixed(3)}px`;
    setTimeout(function setTop() {
      angular.element(myContainer).css({
        top: containerTop,
        minWidth: containerMinWidth,
      });
    }, 50);
  }

  clearSearchTerm() {
    this.userSearchTerm = "";
  }

  /// Private Functions ///

  async _loadData() {
    this.isLoading = true;
    let roles = [];

    if (this._Authentication.isInAnyRole(["triageTech"])) {
      roles = ["tech", "triageTech"];
    } else {
      roles = ["facilityAdmin", "triageTech", "tech", "tzAdmin"];
    }

    // If this item belongs to a delegate facility, users from the current user's
    // facility should be included in the assignment list
    const {
      user: {facility},
    } = this._Authentication.getJwtPayload();
    const itemBelongsToDelegate = Object.keys(facility.relationsToOwnerFacilities || {}).some((id) =>
      this._item.facilityId.includes(id)
    );

    const facilityIds = this._Facility.calculateAncestorFacilityIds(this._item.facilityId);
    if (itemBelongsToDelegate) {
      facilityIds.push(facility.id);
    }
    const userQuery = {
      state: {$notIn: ["pending", "disabled"]},
      role: {$or: roles},
      facilityId: {$or: facilityIds},
    };

    try {
      this._item.assignedUsers = (
        await this._ItemAssignmentService.getAssignedUsers(this._item.id)
      ).data.users;
    } catch (error) {
      if (error.status === 404 && error.data.title === "No item assignments found") {
        this._item.assignedUsers = [];
      }
    }

    try {
      this.users = await this._UserService.getUsers(userQuery);

      const assignedUserIds = this._item.assignedUsers.map((user) => user.userId);

      if (assignedUserIds.length > 0) {
        // Prefill users assigned to this item
        this.assignItemForm.assignItemToUser.$$ngModelSet(this._$scope, assignedUserIds);
      }
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  _deselectInboxItem() {
    return new Promise((resolve) => {
      const deregister = this._$rootScope.$on("inbox-item-deselected", () => {
        resolve();
        deregister();
      });

      this._$rootScope.$emit("deselect-inbox-item");
    });
  }

  _displayErrorMessage(popupTitle, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
    );
  }
}
