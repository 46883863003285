// import angular from "angular";

/* @ngInject */
export default class ItemConfirmationDialogController {
  constructor(item, action, headerText, buttonText, errorMessage, warningMessage, isRaised, $mdDialog) {
    this._$mdDialog = $mdDialog;

    this.item = item;
    this.action = action;
    this.headerText = headerText;
    this.buttonText = buttonText;
    this.errorMessage = errorMessage;
    this.warningMessage = warningMessage;
    this.isRaised = isRaised;
  }

  hide(result) {
    this._$mdDialog.hide(result);
  }
}
