/* eslint-env browser */
import {DateTime} from "luxon";

import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class RawHrDataController {
  constructor($scope, $injector, $mdDialog, enrollmentId, studyId, timeZone, startTime, endTime, parentType) {
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this.enrollmentId = enrollmentId;
    this.studyId = studyId;
    this.timeZone = timeZone;
    this.startTime = startTime;
    this.endTime = endTime;
    this.parentType = parentType;
    this.loadingStatus = "notLoaded";
    this.features = $injector.get("Config").features;

    this._hrService = $injector.get("HeartRateService");
    this._excludeArtifactService = $injector.get("ExcludeArtifactService");

    this.instructions = `Click and drag on the average Heart Rate graph to exclude regions of time.${parentType === "Summary" ? " Scroll to see more." : ""}`;
    this.displayedTimeRange = "";

    this.hrData = [];
    this.$onInit = this._init;
  }

  /// Public Functions ///

  cancel() {
    this._$mdDialog.cancel("Closed exclude heart rate data dialog");
  }

  save() {
    this._excludeArtifactService
      .save(this.enrollmentId)
      .then(() => {
        this._$mdDialog.hide();
      })
      .catch((error) => {
        const popupTitle = "Failed to save artifact regions.";
        const defaultMessage = `Unable to save changes to regions. Status Code: ${error.status}`;
        this._displayErrorMessage(popupTitle, defaultMessage, error);
      });
  }

  /// Private Functions ///
  async _init() {
    this.loadingStatus = "loading";

    try {
      [this.hrData, this.savedRegions] = await Promise.all([
        this._hrService.getHeartRateData(this.startTime, this.endTime, this.studyId),
        this._excludeArtifactService.getArtifactRegionsForStudy(this.studyId, {
          // Query for all Exclusion Regions that are partially or fully within the bounds
          startTime: {$lt: DateTime.fromMillis(this.endTime).toUTC().toFormat("yyyy-MM-dd HH:mm:ss.SSS")},
          endTime: {$gt: DateTime.fromMillis(this.startTime).toUTC().toFormat("yyyy-MM-dd HH:mm:ss.SSS")},
        }),
      ]);

      if (this.hrData.length > 0) {
        this.loadingStatus = "loaded";

        this.displayedTimeRange = `(${formatDateAndTime({
          datetime: this.hrData[0].ts,
          zone: this.timeZone,
          format: "yyyy-MM-dd HH:mm:ss",
        })} to ${formatDateAndTime({
          datetime: this.hrData[this.hrData.length - 1].ts,
          zone: this.timeZone,
          format: "yyyy-MM-dd HH:mm:ss ZZZZ",
        })})`;
      } else {
        this.loadingStatus = "none";
      }
    } catch (error) {
      this.loadingStatus = "error";
      console.error(error);
      this.hrData = [];
    }
    this._$scope.$apply();
  }

  _displayErrorMessage(popupTitle, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
        .multiple(true) // This must be included on the child mdDialog when there are nested mdDialogs
    );
  }
}
